/**
 * 에러 토스트 띄우기
 * @param message
 * @param autoClose 자동닫힘 여부 또는 시간 (false: 자동 닫힘 끄기, true: 5초, 숫자: 해당 초)
 */
function showErrorToast(
  message = '일시적인 오류가 발생하였습니다.\n잠시 후 다시 시도해주세요.',
  autoClose: boolean | number = false
) {
  useNuxtApp().$toast.error(message, {
    toastId: 'errorRetry',
    autoClose
  })
}

export { showErrorToast }
